import React, {Fragment, useState} from 'react';
import {NavLink, useHistory} from 'react-router-dom';
import {useEffectOnce} from 'react-use';
import {Dropdown, Menu, Modal,message} from 'antd';
import {CaretDownOutlined,PhoneFilled} from '@ant-design/icons';
import {useIsLogin, useUserInfo} from '../hook';
import {ServicesModel} from '../common';
import {store} from '../helps';
import cookie from 'react-cookies';
import css from './top-header.module.less';
import avatar from 'aliasimgurl/default_avatar.png';
import iconMobile from 'aliasimgurl/icon_mobile.png';
import {MASTER_URL} from "../common/static_number";

export const TopHeader = (props)=>{
    const [data] = useUserInfo();

    const [isLogin] = useIsLogin();
    const [city,setCity] = useState('加载中...');
    const [timer,setTimer] = useState(null);
    const [visible,setVisible] = useState();
    const handleLogout = ()=>{
        ServicesModel.doLogout().subscribe({
            next:res=>{
                store.clearAll();
                const domain = document.domain === 'localhost' ? document.domain : "."+document.domain.split('.').slice(-2).join('.')
                cookie.remove('token', { domain,path: '/' });
                message.success('退出成功！',3, window.location.href = '/');
            },
            error:err=>{},
            complete:()=>{
                store.clearAll();
            }
        });
    }
    useEffectOnce(()=>{
        function updateCity() {
            let _city = window.currentCity;
            setCity(_city||'加载中...');
            if (!_city) {
                let _timer = setTimeout(()=>{
                    updateCity();
                },1000);
                setTimer(_timer);
            };
        };
        updateCity();
        return ()=>{
            if (timer) {
                clearInterval(timer);
            }
        }
    });
    const {push} = useHistory();
    const onClick = ({key})=>{
        console.log("key",key);
        push(key);
    }
    const menu = (
        <Menu onClick={onClick} className={css.items}>
            <Menu.Item key="/account/setting" className={css.item}>账户设置</Menu.Item>
            <Menu.Item key="/account/work" className={css.item}>消息通知</Menu.Item>
            <Menu.Item key="/account/project" className={css.item}>项目管理</Menu.Item>
            <Menu.Item key="/account/collect" className={css.item}>收藏夹</Menu.Item>
        </Menu>
    );
    return (
        <div className={ css['top-header'] }>
            <div className="container">
                <div className={ css.wraper }>
                    <div className={ css.welcome }>
                        <div>
                            您好，欢迎进入百大项目库！
                        </div>
                        {/*<div>*/}
                        {/*    <EnvironmentFilled /> {city.replace('市','')}*/}
                        {/*</div>*/}
                        <div>
                            <img src={iconMobile} alt="" /> &nbsp;&nbsp;联系电话：400-660-0813
                        </div>
                    </div>
                    <div className={ css.ext }>
                        {
                            isLogin?
                            <Fragment>
                                <a href={MASTER_URL} style={{color: '#333'}}> 返回主站</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <a className={css.userinfo} href={'/account/setting'}>
                                    <img src={data.avatar || avatar} alt=""/>
                                    <span>{data.mobile}</span>
                                </a>&nbsp;&nbsp;

                                <Dropdown overlay={menu} onVisibleChange={visible => setVisible(visible)} placement={'bottomCenter'}>
                                    {/*<a className="ant-dropdown-link" onClick={e => e.preventDefault()}>*/}
                                    {/*    Hover me, Click menu item <DownOutlined />*/}
                                    {/*</a>*/}
                                   <a style={{color: visible?'#14AF97':'#14AF97'}}  href={'/account/setting'}>我的账户 <CaretDownOutlined /></a>
                                </Dropdown>
                                <i></i>
                                <a onClick={ handleLogout }>退出</a>
                            </Fragment>:
                            <Fragment>
                                <NavLink to="/login">请登录</NavLink>
                                <i></i>
                                <NavLink to="/reg">注册</NavLink>
                            </Fragment>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}