import React from 'react';
import css from './footer.module.less';
import {useHistory} from "react-router-dom";
import ga from 'aliasimgurl/ga.png';
import QRcode from 'aliasimgurl/QR_code.png';

export const Footer = ()=>{
    const {push} = useHistory();
    return (
        <div className={ css.footer }>
            <div className={css.ext}>
                    <div className="container">
                        <div className={css.content}>
                            <div className={css.extRight}>
                                <div className={css.row}>
                                    <a href="/about/home">关于我们</a>
                                    <a href="/about/state">服务介绍</a>
                                    <a href="/about/map">网站地图</a>
                                    <a href="/about/copyright">版权所有</a>
                                    <a href="/about/authority">用户权限</a>
                                    <a href="/about/suggestion">意见反馈</a>
                                </div>
                            </div>
                            <div className={css.extLeft}>
                                <div className={css.contact}>
                                    <div>服务热线：400-660-0813</div>
                                    <div>投诉建议：service@china-forest.com</div>
                                </div>
                                <div className={css.QRcode}>
                                    <img src={QRcode} alt=""/>
                                    <div className={css.text}>官方微信</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            <div className={ css.footerBottom }>
                <div>
                    © 2020 版权所有 中国林业产业服务与交易平台 京ICP备20019858号-1
                </div>
                <div>
                    增值电信业务经营许可证：京20204808号
                </div>
                <div>
                    <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010102004231"><img src={ga} /><span>京公网安备 11010102004231号</span></ a>
                </div>
                {/*  <div>
                    运营服务：北京中镇生产力科技中心 中镇枫林规划设计研究院
                </div>
                <div>
                    技术支持：中镇生态科技河北有限公司
                </div>*/}
            </div>
        </div>
    );
}