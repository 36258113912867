import React, {useEffect, useState} from 'react';
import css from './add-project.module.less';
import classNames from 'classnames';
import moment from 'moment';
import {AddProjectSteps, AddressItem, UploadFile, UploadImage,} from "../../components";
import {Button, DatePicker, Form, Input, message, Select, Tabs, Checkbox, Modal} from 'antd';
import {AddProjectIndustry} from "../../components/add-project-industry";
import {useDataApi, useIsLogin} from "../../hook";
import {ProModal, ServicesModel} from "../../common";
import {tap} from "rxjs/operators";
import {SERVICE_PACTION} from "../../helps/helpsPaction";
import {useEffectOnce} from 'react-use';
import banner from 'aliasimgurl/banner_01.png'
import awaitImg from 'aliasimgurl/icon_await.png'
import successImg from 'aliasimgurl/icon_success.png'
import rejectImg from 'aliasimgurl/icon_reject.png'
import {ProView} from "../projestList/view";

const {TextArea} = Input;
const {Option} = Select;
const {TabPane} = Tabs;

const layout = {
    labelCol: {span: 5,},
    wrapperCol: {span: 10},
};

export const AddProject = (props) => {
    const params = props.location.state || {};
    const [projectId,setProjectId] = useState('');
    const [step, setStep] = useState(0);
    const [count, setCount] = useState(60);
    const [initData, setInitData] = useState({});
    const [countdown, showCountdown] = useState(false);
    const [previewFlag, showPreview] = useState(false); //预览弹窗控制
    const [isLogin] = useIsLogin();

    const [form] = Form.useForm();

    const [{data: propertyList}] = useDataApi({url: '/pro/getnature', method: 'get', initialData: []});
    const [{data,isLoading},updateParams] = useDataApi();
    useEffectOnce(() => {
        if(isLogin){
            Modal.warning({
                title:'温馨提示',
                content: '您还未登录，是否现在登录？',
                okText:'去登录',
                cancelText:'取消',
                maskClosable: true,
                onOk: ()=>{
                    window.location.href = '/login'
                },
                onCancel:()=>{

                }
            });
        }

        function scrollToTop(argument) {
            const c = document.documentElement.scrollTop || document.body.scrollTop;
            if (c > 0) {
                window.requestAnimationFrame(scrollToTop);
                window.scrollTo(0, c - c / 2);
            };
        };
        scrollToTop();
    });
    useEffect(()=>{
        const {id = ''} = params ||{};
        if(id){
            setProjectId(id);
            updateParams({url: '/pro/prodetail', method: 'post', data: {project_id:id,type:1}})
        }else{
            setInitData({isLoading:true,...params})
        }
    },[params]);
    useEffect(()=>{
        const {type=''} = params;
        if(!isLoading){
            const _data = data;
            const {province_id,city_id,county_id,industry_id,starttime} = _data;
            _data.isLoading = true;
            _data.area =  [province_id,city_id,county_id];
            _data.industry = industry_id;
           _data.starttime = moment(starttime) || null;
           if(type === 'detail'){
               console.log();
               //1=待审核，2=审核通过，3=审核未通过,4=待提交
               if(_data.status === 1){
                    setStep(3);
               }else if(_data.status === 2 || _data.status === 3){
                   setStep(4);
               }
           }
            setInitData({..._data});
        }
    },[isLoading]);
    const onsubmit = ({type}) => {
        if(type==='submit' && !form.getFieldsValue(['code'])){
            message.error('请输入手机验证码')
            return
        }
        const status_type = type ==='submit'? 2:1;
        form.validateFields().then((v)=>{
            const [province_id, city_id, county_id] = v.area;
            const {id:industry_id,name:industry_name} = v.industry;
            v.starttime = v.starttime ? v.starttime.format('YYYY-MM-DD HH:mm') : '';
            ProModal.addPro({id:projectId,status_type,province_id, city_id, county_id,industry_name,industry_id, ...v}).then(({data: {msg, code,data}}) => {
                if (code === 200) {
                switch (type) {
                    case 'submit':
                        message.success("提交成功");
                        setStep(Number.parseInt(step) + 1);
                        return
                    case 'save' :
                        setProjectId(data);
                        message.success("保存成功");
                        return;

                    case 'preview' :
                        setProjectId(data);
                        showPreview(true);
                        return;
                    default:
                }

                } else {
                    message.error(msg)
                }
            })
        })


    };

    const nextStep = () => {
        console.log("test", step);
        switch (step.toString()) {
            case '0' :
                form.validateFields(['name', 'type', 'industry', 'stage_id', 'area', 'budget', 'remark']).then(value => {
                    setStep(Number.parseInt(step) + 1)
                }).catch(errorInfo => {
                    console.log("errorInfo", errorInfo);
                });
                return;
            case '1' :
                form.validateFields(['woodland', 'woodremark', 'nature_id', 'starttime', 'management', 'payback_cycle', 'benefit', 'policy']).then(value => {
                    setStep(Number.parseInt(step) + 1)
                });
                return;
            case '2' :
                form.validateFields(['name', 'type', 'industry', 'stage_id', 'area', 'budget', 'remark']).then(value => {
                    setStep(Number.parseInt(step) + 1)
                });
                return;
            case '3' :
                return

        }
    };
    /** 获取手机验证码 */
    const getCode = () => {
        const mobile = form.getFieldValue('user_phone');
        startCountdown();
        ServicesModel.getSendCode({mobile, event: 'bigproject'}).pipe(
            tap(res => console.log(res))
        ).subscribe({
            next: (res) => {
                message.success('验证码已发送')
            }
        });
    };
    let IntervalId = '';
    const startCountdown = () => {
        showCountdown(true);
        let _count = count;
        IntervalId = setInterval(() => {
                if (_count > 0) {
                    _count--;
                    setCount(_count);
                } else {
                    showCountdown(false);
                    clearInterval(IntervalId);
                    setCount(60);
                }
            }
            , 1000);

    };

    const [serviceVisible, setServiceVisible] = useState(false);
    const [pactionFlag, setPactionFlag] = useState(false);

    const pactionChange = (e) => {
        setPactionFlag(e.target.checked);
    };

    return (
        <div className={classNames(css.box)}>
            <div className={'container'}>
                <img className={css.banner} src={banner} alt=""/>
                <div className={css.content}>
                    <AddProjectSteps current={step}/>
                    {initData.isLoading &&
                        <Form form={form} className={css.form} initialValues={initData}  {...layout}>
                        <Tabs activeKey={step.toString()}>
                            <TabPane tab="填写基本信息" key="0">
                                <Form.Item label={'项目名称'} name={'name'} initialValue={initData.name}  rules={[{required: true, message: '请填写项目名称'}]}>
                                    <Input placeholder={'请输入项目名称'}/>
                                </Form.Item>
                                <Form.Item label={'项目类型'} name={'type'}  rules={[{required: true, message: '请填写项目类型'}]}>
                                    <Select placeholder={'请选择项目类型'} >
                                        <Option value={1}>财政部项目</Option>
                                        <Option value={2}>发改委项目</Option>
                                        <Option value={3}>地方项目</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item label={'所属行业'} name={'industry'} wrapperCol={14}
                                           rules={[{required: true, message: '请选择所属行业'}]}>
                                    <AddProjectIndustry/>
                                </Form.Item>
                                <Form.Item label={'项目进度'} name={'stage_id'} rules={[{required: true, message: '请选择项目进度'}]}>
                                    <Select placeholder={'请选择项目进度'}>
                                        <Option value={1}>发起阶段</Option>
                                        <Option value={2}>采购阶段</Option>
                                        <Option value={3}>执行阶段</Option>
                                        <Option value={4}>移交阶段</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item label={'所属地区'} name={'area'}
                                           rules={[{required: true, message: '请选择所属地区',},({ getFieldValue }) => ({
                                               validator(rule, value) {
                                               if(value && value[3]){
                                               return Promise.reject('请选择所属地区');
                                           }
                                               return Promise.resolve();
                                           },
                                           })]}>
                                    <AddressItem size="default"/>
                                </Form.Item>
                                <Form.Item label={'详细地址'} name={'address'}
                                           rules={[{required: true, message: '请输入详细地址'}]}>
                                    <Input placeholder={'请填写详细地址'} />
                                </Form.Item>
                                <Form.Item label={'投资预算'} name={'budget'}
                                           rules={[{required: true, message: '请填写投资预算'}]}>
                                    <Input placeholder={'请填写投资预算'} suffix={'亿元'}/>
                                </Form.Item>
                                <Form.Item label={'项目简介'} name={'remark'} wrapperCol={14}
                                           rules={[{required: true, message: '请填写项目简介'}, {
                                               type: 'string',
                                               max: 500,
                                               message: '请控制在500字以内'
                                           }]}>
                                    <TextArea  autoSize type="text" placeholder={'请重点介绍项目特色、优势等，限500字以内'}/>
                                </Form.Item>
                                <Form.Item className={css.trigger} wrapperCol={{offset:5}} >
                                    <Button type="primary" onClick={nextStep}>下一步</Button>
                                </Form.Item>
                            </TabPane>
                            <TabPane tab="填写效益信息" key="1">
                                <Form.Item label={'用地规模'} name={'woodland'}
                                           wrapperCol={{span:8}}
                                           rules={[{required: true, message: '请填写用地规模'}]}>
                                    <Input placeholder={'请填写用地规模'} suffix={'亩'}/>
                                </Form.Item>
                                <Form.Item label={'用地描述'} name={'woodremark'}
                                           wrapperCol={{span:13}}
                                           rules={[{required: true, message: '请填写用地规模'}]}>
                                    <Input placeholder={'输入用地规模中农林用地、建设用地各占多少亩'}/>
                                </Form.Item>
                                <Form.Item label={'用地性质'} name={'nature_id'}
                                           wrapperCol={{span:8}}
                                >
                                    <Select placeholder={'请选择用地性质'}>
                                        {propertyList && propertyList.map((opt) => <Option
                                            value={opt.id}>{opt.name}</Option>)}
                                    </Select>
                                </Form.Item>
                                <Form.Item label={'发起时间'} name={'starttime'}
                                           wrapperCol={{span:8}}
                                           rules={[{required: true, message: '请填写发起时间'}]}>
                                    <DatePicker format={'YYYY-MM-DD'}/>
                                </Form.Item>
                                <Form.Item label={'经营周期'} name={'management'}  wrapperCol={{span:8}}
                                           rules={[{required: true, message: '请填写经营周期'}]}>
                                    <Input placeholder={'请输入项目生命周期'} suffix={'年'}/>
                                </Form.Item>
                                <Form.Item label={'回报周期'} name={'payback_cycle'}   wrapperCol={{span:8}}
                                           rules={[{required: true, message: '请填写回报周期'}]}>
                                    <Input placeholder={'请输入前期投资回本所需时间'} suffix={'年'}/>
                                </Form.Item>
                                <Form.Item label={'效益分析'} name={'benefit'} wrapperCol={14}
                                           rules={[{required: true, message: '请填写效益分析'}, {
                                               type: 'string',
                                               max: 200,
                                               message: '请控制在200字以内'
                                           }]}>
                                    <TextArea autoSize type="text" placeholder={'请简要描述项目进入成熟期后，年均回报展望，限200字以内'}/>
                                </Form.Item>
                                <Form.Item label={'公共配套与政策承诺'} name={'policy'} wrapperCol={14}
                                           rules={[{required: true, message: '请填写公共配套与政策承诺'}, {
                                               type: 'string',
                                               max: 200,
                                               message: '请控制在200字以内'
                                           }]}>
                                    <TextArea autoSize type="text"
                                              placeholder={'请简要描述项目周边已有配套设施情况、需给予什么样的政策支持及当地基层政府对该项目的扶持程度等，限200字以内。'}/>
                                </Form.Item>
                                <Form.Item className={css.trigger} wrapperCol={{offset:5}}>
                                    <Button type="primary" className={css.prePage} onClick={() => setStep(Number.parseInt(step) - 1)}>上一步</Button>
                                    <Button type="primary" onClick={nextStep}>下一步</Button>
                                </Form.Item>
                            </TabPane>
                            <TabPane tab="填写投资信息" key="2">
                                <Form.Item label={'投资所需资源'} name={'resources'} wrapperCol={14}
                                           rules={[{required: true, message: '请填写投资所需资源'}]}>
                                    <TextArea autoSize placeholder={'请简要描述该项目投资方所需资源及主管部门对投资方的其他要求（资金除外）'}/>
                                </Form.Item>
                                <Form.Item label={'第三方服务机构'} name={'third_party'} wrapperCol={14}
                                           rules={[{required: true, message: '请填写第三方服务机构'}, {
                                               type: 'string',
                                               max: 200,
                                               message: '请控制在200字以内'
                                           }]}>
                                    <TextArea autoSize type="text"
                                              placeholder={'对于该项目，地方政府是否安排专门的机构服务或投资方对接，如无则填写由市里统一安排······'}/>
                                </Form.Item>
                                <Form.Item label={'上传项目主图'} name={'images'}>
                                    <UploadImage uploadNum={3}/>
                                </Form.Item>
                                <Form.Item label={'上传项目可研报告'} name={'report'} rules={[{required: true, message: '请上传项目可研报告'}]}>
                                    <UploadFile uploadNum={3}  accept='.pdf,.ppt,.pptx,.docx,.doc,.excl,.xlsx' size={500}/>
                                </Form.Item>
                                <Form.Item label={'对接的主管部门'} name={'department'}
                                           rules={[{required: true, message: '请对接的主管部门'}]}>
                                    <Input placeholder={'该项目所对接的基层政府部门名称'}/>
                                </Form.Item>
                                <Form.Item name={'contacts'} wrapperCol={{offset: 5, span: 10}}
                                           rules={[{required: true, message: '请填写对接联系人姓名及联系电话'}]}>
                                    <Input placeholder={'分别输入对接联系人姓名及联系电话'}/>
                                </Form.Item>
                                <Form.Item label={'投资主体单位'} name={'subject_company'}
                                           rules={[{required: true, message: '请填写投资主体单位'}]}>
                                    <Input placeholder={'投资该项目的主体单位名称'}/>
                                </Form.Item>
                                <Form.Item label={'实施责任主体单位'} name={'responsibility_company'}
                                           rules={[{required: true, message: '请填写实施责任主体单位'}]}>
                                    <Input placeholder={'实施该项目的责任主体单位名称'}/>
                                </Form.Item>
                                <Form.Item label={'项目联系人'} name={'username'}
                                           rules={[{required: true, message: '请填写项目联系人'}]}>
                                    <Input placeholder={'输入联系人姓名'}/>
                                </Form.Item>
                                <Form.Item label={'联系人电话'} name={'user_phone'}
                                           rules={[{required: true, message: '请输入联系人手机号'}]}>
                                    <Input placeholder={'输入联系人手机号'}/>
                                </Form.Item>
                                <Form.Item label="手机验证码" style={{marginBottom: '0'}} required >
                                    <Form.Item
                                        wrapperCol={{span: 23}}
                                        style={{display: 'inline-block'}}
                                        name={'code'}
                                    >
                                        <Input placeholder='请输入手机验证码' autoComplete="off"/>
                                    </Form.Item>
                                    {
                                        !countdown ?
                                            <Form.Item style={{display: 'inline-block'}}>
                                                <Button type="primary" className={css.codeBtn} onClick={() => {
                                                    getCode();
                                                }}>获取验证码</Button>
                                            </Form.Item> :
                                            <Form.Item style={{display: 'inline-block'}}>
                                                <Button type={"primary"} disabled>{count}s后获取</Button>
                                            </Form.Item>
                                    }
                                </Form.Item>
                                <Form.Item className={css.pactionText} wrapperCol={{offset:5}}>
                                    <Checkbox size="large" onChange={pactionChange}>
                                        我已阅读并同意 <Button
                                        style={{padding: '0', height: '20px', lineHeight: '20px', color: '#1CBB85'}}
                                        type={"link"} onClick={() => {
                                        setServiceVisible(true)
                                    }}>《网站服务协议》</Button>
                                    </Checkbox>
                                </Form.Item>
                                <Form.Item className={css.trigger} wrapperCol={{offset:5}}>
                                    <Button type="primary" className={css.prePage}
                                            onClick={() => setStep(Number.parseInt(step) - 1)}>上一步</Button>
                                    <Button type="primary" onClick={()=>onsubmit({type:'submit'})} disabled={!pactionFlag}>立即发布</Button>
                                </Form.Item>
                                <div className={css.ext}>
                                    <a style={{marginRight:'10px'}} onClick={()=>onsubmit({type:'save'})}>
                                        保存
                                    </a>
                                    <a onClick={()=>onsubmit({type:'preview'}) }>预览</a>
                                </div>
                                <Form.Item label="" wrapperCol={{offset:5}}>
                                    温馨提示<br/>
                                    1、通过发布入口发布的信息，未经网站审核，暂不显示。您可前往个人中心查看项目信息审核状态。<br/>
                                    2、发布过程中，如遇任何问题，请致电客服，电话400-660-0813。<br/>
                                </Form.Item>
                            </TabPane>
                            <TabPane tab="平台审核" key="3" className={css.result}>
                                <div className={css.await}>
                                    <img src={awaitImg} alt="" className={css.status_img}/>
                                    <div className={css.title}>您已提交成功，进入平台审核阶段，请耐心等待！</div>
                                    <div className={css.subtitle}>如有其它任何疑问，请致电400-660-0813</div>
                                </div>
                            </TabPane>
                            <TabPane tab="完成" key="4"  className={css.result}>
                                {
                                    data.status === 2 && <div className={css.success}>
                                        <img src={successImg} alt="" className={css.status_img}/>
                                        <div className={css.title}>恭喜，您提交的项目信息已经通过平台审核，并成功发布！</div>
                                        <div className={css.subtitle}>如有其它任何疑问，请致电400-660-0813</div>
                                    </div>
                                }
                                {
                                    data.status === 3 && <div className={css.reject}>
                                        <img src={rejectImg} alt="" className={css.status_img}/>
                                        <div className={css.title}>抱歉，您提交的项目信息审核不通过，请重新填写提交</div>
                                        <div className={css.subtitle}>如有其它任何疑问，请致电400-660-0813</div>
                                        <div className={css.remark}>
                                            <Form.Item label={'驳回原因'} labelCol={{offset:6}}>
                                                <TextArea autoSize rows={4} value={data.admin_remark} disabled/>
                                            </Form.Item>
                                        </div>
                                        <Form.Item className={css.trigger} wrapperCol={{offset:1}}>

                                            <Button type="primary" onClick={()=>setStep(0)} >重新提交</Button>
                                        </Form.Item>
                                    </div>
                                }

                            </TabPane>
                        </Tabs>

                    </Form>
                    }
                </div>
            </div>
            <Modal visible={serviceVisible} closable={false} footer={null} width={'800px'}>
                <div style={{height: '600px', overflow: 'auto', marginTop: '20px'}}>
                     <div  dangerouslySetInnerHTML={{__html: SERVICE_PACTION}} />
                     <div style={{textAlign: 'center'}}>
                         <Button type={"primary"} size={"large"} onClick={() => setServiceVisible(false)} >我知道了</Button>
                     </div>
                </div>
            </Modal>
            <Modal visible={previewFlag}
                   width={850}
                   onCancel={()=>showPreview(false)}
                   footer={false}
            >
                <ProView match = {{params:{id:projectId,type:'preview'}}} />
            </Modal>
        </div>
    );
}