import React, {Fragment, useEffect} from 'react';
import {NavLink} from 'react-router-dom'
import {useEffectOnce} from 'react-use';
import {Breadcrumb, HotCityList, HotInfoList, UploadImage, ViewBox,} from '../../components';
import {Descriptions, message} from 'antd';
import {useDataApi, useIsLogin} from '../../hook';
import css from './index.module.less';
import {ProModal} from "../../common";
import {tap} from "rxjs/operators";
import {resourcesUrl} from "../../common/static_number";
import banner01 from 'aliasimgurl/obj_banner_01.png';
import {Steps} from "../../components/steps";
import print from 'print-js'
import {createFromIconfontCN} from '@ant-design/icons';
import {useHistory} from "react-router-dom";
import {getSearch} from "../../helps";

const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1696985_rli4q8kct8f.js',
});

export const ProView = (props) => {
    const [isLogin] = useIsLogin();
    const  {id='',type=''} = props.match.params?props.match.params:{};

    const [{data,isLoading,setData},updateParams] = useDataApi();
    const {push} = useHistory();
    useEffectOnce(() => {
        function scrollToTop(argument) {
            const c = document.documentElement.scrollTop || document.body.scrollTop;
            if (c > 0) {
                window.requestAnimationFrame(scrollToTop);
                window.scrollTo(0, c - c / 2);
            };
        };
        scrollToTop();
    });
    useEffect(()=>{
      if(id){
          updateParams({url: '/pro/prodetail', method: 'post', data: {project_id:id,type:1}})
      }
    },[id])

    /** 收藏 */
    const handleCollect = ()=>{
        ProModal.collect({id,type:7}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:res=>{
                message.success('收藏成功',3);
                const _data = data;
                _data.is_collect = true;
                setData({..._data})
            },
            error:err=>{},
            complete:()=>{}
        });
    };
    /** 取消收藏 */
    const handleCancelCollect = ()=>{
        ProModal.cancelCollect({id,type:7}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:res=>{
                message.success('取消成功',3);
                const _data = data;
                _data.is_collect = false;
                setData({..._data})
            },
            error:err=>{},
            complete:()=>{}
        });
    };
    const handlePrint = () =>{
        print({printable: 'printDom',
            type: 'html',
            documentTitle: '',
            header: data.name,
            headerStyle: 'font-size: 28px;text-align:center',
            // 继承原来的所有样式
            targetStyles: ['*']});
    }
    return (
        <div className="container">
            {/*<YcImage imgUrl={ headerImg } />*/}
            {!isLoading &&
                <div className={css.bodyWarp}>
                    <div className={css.leftWarp} >
                        <Breadcrumb list={ [{name:'最新项目',path:'/obj/new'},{name:data.name||""}] } />
                        <div className={ css.viewWrap }>
                            <div className={ css.titleInfo }>
                                <h2 className={ css.viewTitle }>{data.name}</h2>
                                <p>
                                    <span><IconFont type={'icon-time'}/><span className={css.text}>{data.createtime}</span></span>
                                    <span><IconFont type={'icon-chakan'}/><span className={css.text}>{data.read_num}</span></span>
                                    {
                                        data.is_collect?  <span className={css.check} style={{cursor: "pointer"}} onClick={handleCancelCollect} >
                                            <IconFont type={'icon-collection-b'}/>
                                            <span className={css.text} >已收藏</span>
                                        </span>:<span  onClick={handleCollect} style={{cursor: "pointer"}}>
                                           <IconFont  type={'icon-shoucang1'}/><span className={css.text}>收藏</span>
                                        </span>
                                    }

                                    {/*<i className="iconfont iconfenxiang"></i>*/}
                                    <span onClick={handlePrint} style={{cursor: "pointer"}}><IconFont  type={'icon-daochu'}/><span className={css.text}  >导出</span></span>
                                    <span onClick={handlePrint} style={{cursor: "pointer"}}>
                                         <IconFont  type={'icon-dayin'}/>
                                        <span className={css.text}>打印</span>
                                     </span>
                                </p>
                            </div>
                            <div id={'printDom'}>
                                <ViewBox name={'项目概况'} style={{marginTop:'0'}}>
                                        <Descriptions column={2} bordered>
                                            <Descriptions.Item label={'投资预算'}>
                                                {data.budget}亿元
                                            </Descriptions.Item>
                                            <Descriptions.Item label={'项目类型'}>
                                                {
                                                    function (){
                                                        switch (data.type) {
                                                            case 1:
                                                                return '财政部项目';
                                                            case 2:
                                                                return '发改委项目';
                                                            case 3:
                                                                return '地方项目';
                                                            default:
                                                        }
                                                    }()
                                                }
                                            </Descriptions.Item>
                                            <Descriptions.Item label={'所属地区'}>
                                                {data.area}
                                            </Descriptions.Item>
                                            <Descriptions.Item label={'所属行业'}>
                                                {data.industry_name}
                                            </Descriptions.Item>
                                            <Descriptions.Item label={'经营周期'}>
                                                {data.management}年
                                            </Descriptions.Item>
                                            <Descriptions.Item label={'回报周期'}>
                                                {data.payback_cycle}年
                                            </Descriptions.Item>
                                            <Descriptions.Item label={'发起时间'}>
                                                {data.starttime}
                                            </Descriptions.Item>
                                            <Descriptions.Item label={'用地规模'}>
                                                {data.woodland}亩
                                            </Descriptions.Item>
                                            <Descriptions.Item label={'投资主体'}>
                                                {data.subject_company}
                                            </Descriptions.Item>
                                            <Descriptions.Item label={'实施主体'}>
                                                {data.responsibility_company}
                                            </Descriptions.Item>
                                            <Descriptions.Item label={'联系人'}>
                                                {data.username}
                                            </Descriptions.Item>
                                            <Descriptions.Item label={'联系电话'}>
                                                {
                                                    isLogin?
                                                        <span >{data.user_phone}</span>:
                                                        <span>
                                                            <NavLink to="/login">登录</NavLink>
                                                            后可查看
                                                        </span>
                                                }
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </ViewBox>
                                <ViewBox name={'项目进度'} >
                                    <div style={{border: '1px solid #eee',padding: '0 60px'}}>
                                        <Steps current={data.stage_id} />
                                    </div>
                                </ViewBox>
                                <ViewBox name={'项目介绍'} >
                                    <Descriptions column={1} bordered>
                                        <Descriptions.Item label='项目简介'>
                                            {data.remark}
                                        </Descriptions.Item>
                                        <Descriptions.Item label='用地描述'>
                                            {data.woodremark}
                                        </Descriptions.Item>
                                        <Descriptions.Item label='效益分析'>
                                            {data.benefit}
                                        </Descriptions.Item>
                                        <Descriptions.Item label='公共配套与政府承诺'>
                                            {data.policy}
                                        </Descriptions.Item>
                                        <Descriptions.Item label='投资所需资源'>
                                            {data.resources}
                                        </Descriptions.Item>
                                        <Descriptions.Item label='第三方服务机构'>
                                            {data.third_party}
                                        </Descriptions.Item>
                                        <Descriptions.Item label='对接的基层政府部门'>
                                            {data.department} &nbsp;&nbsp;&nbsp;&nbsp;
                                            联系人及联系方式：{data.contacts}
                                        </Descriptions.Item>
                                        {data.report &&  <Descriptions.Item label='文件下载'>
                                            {  data.report.map((item)=>
                                                <a href={ resourcesUrl + item.file} download={item.name} style={{display: 'block'}} target={'_blank'}>{item.name}</a>
                                            )}

                                        </Descriptions.Item>}
                                    </Descriptions>

                                </ViewBox>
                                {data.images &&
                                <div  style={{marginTop:'10px'}}>
                                    <UploadImage disabled={true} uploadNum={3} value={data.images || []}/>
                                </div>}
                            </div>
                        </div>
                    </div>
                    {type !== 'preview' &&
                        <div className={css.rightWarp}>
                            <div className={css.bannerWarp} onClick={()=>push({pathname:'/addproject',state:{}})}>
                                <img src={banner01} alt=""/>
                            </div>
                            <HotCityList style={{marginTop: '20px'}}/>
                            <HotInfoList style={{marginTop: '20px'}}/>
                        </div>
                    }

                </div>
            }
        </div>
    )
}