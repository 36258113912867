import React from 'react';
import {CardBox, CommonList} from '../components';
import css from './index.module.less';
import {useDataApi} from "../hook";
import {useHistory} from "react-router-dom";
import {IndexFocus, InfoProjectList,FineProjectList,NewProjectList} from './components/home';

export const Home = (props)=>{
    const [{data,isLoading }] = useDataApi({ url: '/pro/indexinfo', method: 'post', initialData: {}});
    const [{data:supplyList}] = useDataApi({ url: '/api/getsupplyStore', method: 'post', data:{pagesize: 10,flag: 'recommend'},initialData: {}});
    const {push} = useHistory();
    console.log(".........................................");
    const goDetail= (type,opt)=>{
        /* banners banner图 hotType 热门分类 hot 热门项目 area 地区 gd 滚动 zh 招标 cg 采购 news 新闻 zb 中标公示 bk 招标百科 download 下载 */
        switch (type) {
            case 'detail':
                push({pathname:'/obj/view/'+opt.id});
                break;
            case 'info':
                push({pathname:'/info/view/'+opt.id});

                break;
            default:
        }
    };
    const goList = (type)=>{
        switch (type) {
            case 'info':
                push({pathname:'/info'});
                break;
            case 'detail':
                push({pathname:'/obj/new'});
                break;
            default:
        }

    };
    return (
        <div className={css.home}>
            {
                !isLoading &&   <div className="container">
                    <div className="container">
                        <IndexFocus banners={data.banners} area={data.area} protype={data.protype} goDetail={goDetail}/>
                    </div>
                    <div className={ css.mainWithBar }>
                        <div className={ css.leftPart }>
                            <CardBox title="精品项目" >
                                <FineProjectList items={data.boutique} goDetail={e => goDetail('detail',e)}/>
                            </CardBox>
                        </div>
                    </div>
                    <div className={ css.mainWithBar }>
                        <div className={ css.leftPart }>
                            <CardBox title="最新项目"  more={ ()=>goList('detail') }>
                                <NewProjectList items={data.newprojects} goDetail={e => goDetail('detail',e)}/>
                            </CardBox>
                        </div>
                        <div className={css.banners}>
                            <a href={'/obj/NDRC'} />
                            <a href={'/obj/exchequer'} />
                            <a href={'/obj/local'} />
                        </div>
                    </div>
                    <div className={ css.mainWithBar }>
                        <div className={ css.leftPart }>
                            <CardBox title="项目资讯"  more={ ()=>goList('info') } style={{  width: '815px',marginTop: '25px'}}>
                                <InfoProjectList items={data.news} goDetail={e => goDetail('info',e)}/>
                            </CardBox>
                        </div>
                        <div className={ css.sideBar }>
                            <CommonList  items={supplyList.list}/>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}
